
<template>

  <div class="carne">
    <div class="col-md-12 text-right mt-3 noprint">
      <button type="button" class="btn btn-primary " @click="back">
        <i class="fas fa-arrow-circle-left"></i>
        Voltar
      </button>
      <button type="button" class="btn btn-primary ml-2" @click="print">
        <i class="fas fa-print"></i>
        Imprimir KOBANA
      </button>
      <button type="button" class="btn btn-primary ml-2" @click="saveBtg">
        <i class="fas fa-print"></i>
        Imprimir BTG
      </button>
    </div>
    <div v-for="invoice in invoices" :key="invoice.id" class="mb-12 mt-1 text-center"
      v-show="invoice.status == 'OPENED' && !invoice.urlTicket.includes('clubflex') && (invoice.paymentType == 'TICKET' || invoice.paymentType == 'TICKETS') && !invoice.urlTicket.includes('clubflex')">
      <img :src="invoice.urlTicket + '/letter.png?nocache=' + Math.random()" style="width: 920px; height: 650px;" />
      <br>
    </div>
  </div>
</template>

<script>
const optionsAlert = { title: 'Mensagem', size: 'sm' }
import {PDFDocument} from "pdf-lib";

export default {
  props: ['subscriptionId'],
  name: 'carne',
  components: {
  },
  mounted() {
    this.loadInvoiceData()
  },
  data() {
    return {
      invoices: null
    }
  },
  methods: {
    async mergePdfs() {
      const pdfTickets = await PDFDocument.create();
      const invoices = this.invoices.filter(invoice => invoice.paymentType === 'TICKETS' && invoice.status === 'OPENED' && invoice.urlTicket.includes('clubflex'));

      if (invoices.length === 0)
          return

      for(const invoice of invoices) {
        const response = await fetch(invoice.urlTicket);
        const pdf = await PDFDocument.load(await response.arrayBuffer());
        const [page] = await pdfTickets.copyPages(pdf, [0]);

        pdfTickets.addPage(page);
      }

      this.downloadPdf(await pdfTickets.save())
    },
    downloadPdf(pdf) {
      const url = window.URL.createObjectURL(new Blob([pdf]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', "carnê.pdf")
      document.body.appendChild(link)
      link.click()
    },
    loadInvoiceData() {
      this.$clubApi.get('/subscription/' + this.subscriptionId + '/list/invoices?include_canceled=false')
        .then((response) => {
          this.invoices = response.data.object
        }).catch((error) => {
          this.$dialogs.alert(error.response.data.msg, optionsAlert)
        }).finally(() => {
          NProgress.done()
          this.orderInvoices()
        })
    },
    orderInvoices() {
      const parseDate = (date) => {
        const [dia, mes, ano] = date.split("/")
        return new Date(ano, mes, dia)
      }

      this.invoices = this.invoices.sort((a, b) => {
        return parseDate(a.dueDate) - parseDate(b.dueDate)
      })
    },
    print() {
      window.print()
    },
    saveBtg() {
      this.mergePdfs()
    },
    back() {
      window.close()
    }
  }
}
</script>

<style scoped>
@media print {
  .noprint {
    display: none;
  }
}
</style>